import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import LoginReg from "./Screens/UserLoginReg/LoginReg";
import ManageProxy from "./Screens/ManageProxyScreen/ManageProxy";
import MychitsHome from "./Screens/MychitsScreen/MychitsHome";
import MyRequest from "./Screens/MyRequestScreen/MyRequest";
import PaymentHistory from "./Screens/PaymentHistoryScreen/PaymentHistory";
import { ContextAPI } from "./common/Context/ContextApi";
import Logout from "./Screens/Logout/Logout";
import ProfileScreen from "./Screens/ProfileScreen/ProfileScreen";
import Register from "./Screens/Register/Register";
import ForgotPassword from "./Screens/ForgotPasswordScreen/ForgotPassword";
import ForgotUsername from "./Screens/ForgotUsernameScreen/ForgotUsername";
import ChangeForgotPassword from "./Screens/ChangeForgotPasswordScreen/ChangeForgotPassword";
import ChangeForgotUsername from "./Screens/ChangeForgotUsernameScreen/ChangeForgotUsername";
import ChitDetails from "./Screens/MychitsScreen/ChitDetailsScreen/ChitDetails";
import NewChits from "./Screens/NewChitScreen/NewChits";
import EMICalculator from "./Screens/EMICalculatorScreen/EMICalculator";
import Feedback from "./Screens/FeedBackScreen/Feedback";
import ChittalDetails from "./Screens/MychitsScreen/ChittalDetailsScreen/ChittalDetails";
import RequestChat from "./Screens/ChatScreen/RequestChat";
import ViewPassBook from "./Screens/ChitPassbookScreen/ViewPassBook";
import PaymentList from "./Screens/PaymentScreen/PaymentList";
import AuctionDetails from "./Screens/AuctionDetailScreen/AuctionDetails";
import QuickPay from "./Screens/QuickPay/QuickPay";
import MyAccount from "./Screens/MyAccountScreen/MyAccount";
import LoanList from "./Screens/MyLoans/LoanList/LoanList";
import LoanDetails from "./Screens/MyLoans/LoandDetails/LoanDetails";
import ViewLoanPassBook from "./Screens/LoanPassbookScreen/ViewLoanPassBook";
import { FailedPopup, clearSessionStorage } from "./common/Utils/Utils";
import { ManageApis } from "./common/APIS/ManageApis";
import { useIdleTimer } from "react-idle-timer";
import { Modal } from "@arco-design/web-react";
import PopupModal from "./Components/PopupModal";
import NetworkUnavailable from "./Screens/NetworkUnavailableScreen/NetworkUnavailable";

const registerComponent = [
  { url: "/login", component: <Login /> },
  { url: "/register", component: <Register /> },
  { url: "/register/verifyotp", component: <Register /> },
  { url: "/forgotusername", component: <ForgotUsername /> },
  { url: "/forgotusername/verifyotp", component: <ForgotUsername /> },
  { url: "/forgotpassword", component: <ForgotPassword /> },
  { url: "/forgotpassword/verifyotp", component: <ForgotPassword /> },
  { url: "/changeforgotpassword", component: <ChangeForgotPassword /> },
  { url: "/changeforgotusername", component: <ChangeForgotUsername /> },
  { url: "/cart/:userdetails/:auth/:redirect_url", component: <PaymentList /> },
];
const dashboardComponent = ["/settings"].map((item) => ({
  url: item,
  component: <Home />,
}));
const otherLoggedinScreens = [
  { url: "/", component: <Home /> },
  { url: "/manageproxy", component: <ManageProxy /> },
  { url: "/mychits", component: <MychitsHome /> },
  { url: "/myloans", component: <LoanList /> },
  { url: "/loandetails", component: <LoanDetails /> },
  { url: "/chitdetails", component: <ChitDetails /> },
  { url: "/chittaldetails", component: <ChittalDetails /> },
  { url: "/passbook", component: <ViewPassBook /> },
  { url: "/loanpassbook", component: <ViewLoanPassBook /> },
  { url: "/newchits", component: <NewChits /> },
  { url: "/myrequest", component: <MyRequest /> },
  { url: "/emicalculator", component: <EMICalculator /> },
  { url: "/feedback-complaint", component: <Feedback /> },
  { url: "/paymenthistory", component: <PaymentHistory /> },
  { url: "/profile", component: <ProfileScreen /> },
  { url: "/manage-proxy", component: <ManageProxy /> },
  { url: "/logout", component: <Logout /> },
  { url: "/chat", component: <RequestChat /> },
  { url: "/cart", component: <PaymentList /> },
  { url: "/auctiondetails", component: <AuctionDetails /> },
  { url: "/quickpay", component: <QuickPay /> },
  { url: "/myaccount", component: <MyAccount /> },
];

const timeout = 1200000;
const promptBeforeIdle = 30000;

const Router = () => {
  const { settoken, token, setkycModal } = useContext(ContextAPI);
  const { pathname } = useLocation();
  const { auth, userdetails, redirect_url } = useParams();
  const navigate = useNavigate();
  const [loggedIn, setloggedIn] = useState(token ? true : false);
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [NetworkStatus, setNetworkStatus] = useState(true);

  const IsNotLoginScreen = useMemo(
    () => !pathname.includes('/login'),
    [pathname]
  );
  useEffect(() => {
    navigator.onLine ? setNetworkStatus(true): setNetworkStatus(false)
  })
  useEffect(() => {
    const splitPathname = pathname.split("/");
    const is_redirecturl = splitPathname?.some((item) =>
      item.includes("my-ksfe")
    );
    if (token) {
      setloggedIn(true);
    } else {
      if (!is_redirecturl) {
        if (!registerComponent.find((itm) => itm.url === pathname)) {
          navigate("/login", { replace: true });
        }
      }
    }
  }, [pathname, token]);

  const handleClearAuth = async () => {
    await clearSessionStorage();
    settoken("");
    setkycModal(false);
    Modal.destroyAll();
    return navigate("/login", { replace: true });
  };
  const handleLogout = async () => {
    if (token) {
      let response = await ManageApis("get", global.logOut, "", token);
      if (response.message == "Success") {
        handleClearAuth();
      } else {
        if (!response.message.includes("Invalid token provided")) {
          FailedPopup(response);
        }
        handleClearAuth();
      }
    } else {
      handleClearAuth();
    }
  };
  const onIdle = () => {
    if (IsNotLoginScreen) {
      if (logoutConfirmation) {
        handleLogout();
      }
    }
  };

  const onPrompt = () => {
    if (IsNotLoginScreen) {
      setLogoutConfirmation(true);
    }
  };
  const onActive = () => {
    setLogoutConfirmation(false);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    timeout,
    promptBeforeIdle,
    // timeout: 300_000,  // set as 5min
    throttle: 500,
  });

  useEffect(() => {
    if (IsNotLoginScreen) {
      if (!logoutConfirmation) {
        activate(); // Restart idle timer if confirmation is not shown
      }

      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [logoutConfirmation, getRemainingTime, pathname]);
  useEffect(() => {
    if (remaining === 0) {
      {
        handleConfirmation(true);
      }
    }
  }, [remaining]);

  const handleConfirmation = (confirm) => {
    if (confirm) {
      handleLogout();
    }
    setLogoutConfirmation(false);
  };

  const navigations = [
    ...registerComponent,
    ...dashboardComponent,
    ...otherLoggedinScreens,
  ];

  const authMangedNavigation = useMemo(() => {
    if (loggedIn) return navigations;
    else return registerComponent;
  }, [loggedIn]);

  

  return (
    <>
        {logoutConfirmation && (
            <PopupModal
            visible={logoutConfirmation}
            handleClose={() => setLogoutConfirmation(false)}
            title={"Are you still there?"}
            message={`Logging out in ${remaining} seconds`}
            buttons={[{ text: "Yes", onPress: () => handleConfirmation(false) }]}
            />
        )}
        {NetworkStatus ? 
            <Routes>
                {authMangedNavigation.map((item) => (
                <Route element={item.component} path={item.url} />
                ))}
            </Routes>
        :
            <NetworkUnavailable/>
        }
    </>
  );
};

export default Router;
