import { useCallback, useContext, useEffect, useState } from "react";
import BackBtn from "../../../Components/BackBtn/BackBtn";
import Layout from "../../../Components/Layout/Layout";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { ManageApis } from "../../../common/APIS/ManageApis";
import { FailedPopup, preventSpecialCharsandNum} from "../../../common/Utils/Utils";
import DataTable from "../../../Components/DataTable/DataTable";
import { Skeleton } from "@arco-design/web-react";
import NoData from "../../../Components/NoData/NoData";
import { useNavigate } from "react-router-dom";
import InputField from "../../../Components/InputField/InputField";
import BranchList from "../../../Components/BranchList/BranchList";

const LoanList = () => {
    const Navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const [nextPage, setnextPage] = useState(0)
    const [currentPage, setcurrentPage] = useState(1)
    const [totalPages, settotalPages] = useState(0)
    const { token } = useContext(ContextAPI)
  const [list, setList] = useState([])
  const [searchInputvalue, setSearchInputvalue] = useState()
    const [filter, setfilter] = useState({search:"", branch_id:""});
    useEffect(
      () => {
       
        setList([])
        myLoansList()
        return (() => {
          setList([])
        })
      },[filter.branch_id],
    )
    const handleChangeTable = ({ current }) => {
        myLoansList(current, filter)
        setcurrentPage(current)
    }

    const TableColumn = [
        { title: 'Loan category', dataIndex: 'loan_description',},
        { title: 'Branch Name', render: (v, rec) => rec.branch_name },
        { title: 'Account no', dataIndex: 'account_no' },
        {
          title: 'Action', dataIndex: 'action', render: (v, rec) =>
          loading ? <Skeleton text={{ rows: 1, width: 100 }} />
              :
              <div className="d-flex justify-content-end">
                <>
                  <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp" onClick={() => Navigate(`/loandetails`, { state: { loanAccountNumber: rec.account_no ,loanee_id:rec._id } })} >View</button>
                </>
              </div>
        },
        
    ]
    
    const myLoansList = useCallback(async (nextPage, data = filter) => {
        //console({ list })
        
        setloading(true)
        const reqstBody = {
            page: isNaN(nextPage) ? 1 : nextPage,
            size: 10,
            limit: 10,
            search: data.search,
            branchId: Number(data.branch_id) || "",
        }
        //console.log('tok', token)
        let response = await ManageApis('post', global.loanList, reqstBody, token)
        if (response.message == "Success") {
            console.log(response)
            setloading(false)
            if (response?.response_data?.status) {
                let data = []
                data = response.response_data.data.docs
                settotalPages(response.response_data.data.total)
                // console('pagintaion', response.response_data.page > 1)
                if (response.response_data.data.page > 1) {
                    data = [...list, ...response.response_data.data.docs]
                    settotalPages(response.response_data.data.total)
                } else {
                  
                    data = response.response_data.data.docs
                    settotalPages(response.response_data.data.total)
                }
                setList(data)
                //console('new arr--------', data)
                setnextPage(response.response_data.data.nextPage)

            }
        } else {
            setloading(false)
            //console.log(response)
            FailedPopup( response)
        }
    }, [filter, token])
  //   if (response.message === "Success") {
  //     console.log(response);
  //     setloading(false);
  //     if (response?.response_data?.status) {
  //         let data = response.response_data.data.docs.filter(loan => loan.status !== "closed");
  //         settotalPages(response.response_data.data.total);
  //         setList(data);
  //         setnextPage(response.response_data.data.nextPage);
  //     }
  //   } else {
  //     setloading(false);
  //     FailedPopup(response);
  //   }
  // }, [filter, token])
    const handleClear = () => {
      let obj = { ...filter, search: '' }
      setfilter(obj)
      myLoansList(1, obj)
    }
    const handleSubmit = useCallback(() => {
      myLoansList(1, filter)
    }, [filter])

    const handleChangeBranch = (value) => {
        setcurrentPage(1)
        setfilter(prev => ({ ...prev, branch_id: value }))
   
    }
    const handleChangeName = (value) => {
      setcurrentPage(1)
      value =preventSpecialCharsandNum(value)
      setfilter(prev => ({ ...prev, search:value  }))
    }

    return (
        <Layout loading={loading}>
          <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
            <div className="mb-3">
              <div className="align-items-center d-flex justify-content-between flex-wrap">
              <BackBtn title={'My Loans'} />
              <div className="col-12  col-xl-5">
                <div className="bg-white d-grid d-sm-flex gap-2 mt-2 p-2 rounded">
                  <InputField marginBottom label={'Search by Loan Category'} placeholder={"Search by Loan Category"} type={'text'} searchable onchange={handleChangeName} validation={preventSpecialCharsandNum} value={filter?.search} handleSubmit={handleSubmit} onClear={handleClear} />
                  <BranchList marginBottom isUserBranch isLoan onChange={handleChangeBranch} value={filter.branch_id}  />
                </div>
              </div>
              </div>
            </div>
            <div>
              <div className="p-3 bg-white br_10 shadow_sm">
              {list?.length ?
                <div className="arco_table1 table-responsive custom_scroll1">
                  <DataTable 
                    columnData={TableColumn} 
                    rowData={list} 
                    onChangeTable={handleChangeTable} 
                    total={totalPages} 
                    current={currentPage} 
                  />
                </div>
                :
                <NoData title={'No Loans found'} />
                
              }
              </div>
            </div>
          </div>
        </Layout>
      );
}

export default LoanList
