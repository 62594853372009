import { NewspaperOutline } from "react-ionicons";
import Layout from "../../Components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import TableComponent from "../../Components/TableComponent/TableComponent";
import { useCallback, useEffect, useState } from "react";
import { ManageApis } from "../../common/APIS/ManageApis";
import {
  FailedPopup,
  createNewObjectsWithSelectedKeys,
  customLogger,
  preventNumSpecialChars,
  preventSpecialCharsandCharAllowSlash,
} from "../../common/Utils/Utils";
import { useContext } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import DataTable from "../../Components/DataTable/DataTable";
import { Skeleton } from "@arco-design/web-react";
import BackBtn from "../../Components/BackBtn/BackBtn";
import InputField from "../../Components/InputField/InputField";
import BranchList from "../../Components/BranchList/BranchList";
import NoData from "../../Components/NoData/NoData";

function MychitsHome() {
  const navigate = useNavigate();
  const { token } = useContext(ContextAPI);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterList, setFilterList] = useState({
    header: [],
    values: [],
    selectedKey: [],
    linkto: "",
  });
  const [loader, setLoader] = useState(false);

  const [filter, setFilter] = useState({ search: "", branch_id: "" });
  const [nextPage, setNextPage] = useState(0);

  useEffect(() => {
    myChitList();
  }, [filter.branch_id]);

  const handleChangeTable = useCallback(({ current }) => {
    myChitList(current);
    setCurrentPage(current);
  }, []);

  // get chit list
  const myChitList = async (nextPage, data = filter) => {
    setLoader(true);
    const reqstBody = {
      page: nextPage || 1,
      size: 10,
      limit: 10,
      branchId: Number(filter.branch_id) || "",
      search: data.search,  
    };

    let response = await ManageApis("post", global.chitList, reqstBody, token);
    if (response.message === "Success") {
      setLoader(false);
      if (response.response_data.status) {
        let data = [];
        if (response.response_data.data.page > 1) {
          data = [...list, ...response.response_data.data.docs];
        } else {
          data = response.response_data.data.docs;
        }
        setTotalPages(response.response_data.data.total);
        setList(data);
        setFilterList((prv) => ({
          header: ["Chit No", "Chittals Enrolled", "Branch Name"],
          values: data,
          linkto: "/chitdetails",
          selectedKey: ["_id", "branch_name", "no_of_chittals"],
        }));
        setNextPage(response.response_data.data.nextPage);
      }
    } else {
      setLoader(false);
      FailedPopup(response);
    }
  };

  const handleClear = () => {
    let obj = { ...filter, search: "" };
    setFilter(obj);
    myChitList(1, obj);
  };
  const handleSubmit = useCallback(() => {
    myChitList(1, filter);
  }, [filter]);

  const TableColumn = [
    { title: "Chit No", dataIndex: "_id" },
    { title: "Chittals Enrolled", dataIndex: "no_of_chittals" },
    {
      title: "Branch Name",
      render: (v, rec) => (rec.chittals.length > 0 ? rec.chittals[0].branch_name : "-"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (v, rec) =>
        loader ? (
          <Skeleton text={{ rows: 1, width: 100 }} />
        ) : (
          <div className="d-flex justify-content-end">
            <>
              <button
                className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp"
                onClick={() =>
                  navigate(`/chitdetails`, { state: { chit_id: rec._id, branch_id: rec.branch_id } })
                }
              >
                View
              </button>
            </>
          </div>
        ),
    },
  ];

  const handleChangeBranch = (value) => {
    setFilter((prev) => ({ ...prev, branch_id: value }));
  };
  const handleChangchit = (value) => {
    let newvalue = value;
    if(newvalue !== filter.search){
      setFilter((prev) => ({ ...prev, search: newvalue }));
    }
    
  };
  
  return (
    <Layout loading={loader}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-center d-flex justify-content-between flex-wrap">
            <BackBtn title={"My Chits"} />
            <div className="col-12  col-xl-5">
              <div className="bg-white d-grid d-sm-flex gap-2 mt-2 p-2 rounded">
                <InputField
                  marginBottom
                  label={"Search by chitty no"}
                  placeholder={"Search by chitty no"}
                  type={"text"}
                  searchable
                  onchange={handleChangchit}
                  value={filter.search}
                  handleSubmit={handleSubmit}
                  onClear={handleClear}
                />
                <BranchList
                  marginBottom
                  isUserBranch
                  onChange={handleChangeBranch}
                  value={filter.branch_id}
                />
              </div>
            </div>
          </div>
        </div>
          <div>
            <div className="p-3 bg-white br_10 shadow_sm">
              {list.length > 0 ? (
                <div>
                  <div className="p-3 bg-white br_10 shadow_sm">

                    <div className="arco_table1 table-responsive custom_scroll1">
                      <DataTable
                        columnData={TableColumn}
                        rowData={list}
                        onChangeTable={handleChangeTable}
                        total={totalPages}
                        current={currentPage}
                      />
                    </div>

                    
                  </div>
                </div>
              ) : (
                <NoData title={'No Chits found'} />
              )}
            </div>
          </div>
        
      </div>
    </Layout>
  );
}
export default MychitsHome;
