import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Header from "../../Components/Header"
import { Link, useLocation, useNavigate } from "react-router-dom"
import "./Home.css";
import profileImg from "../../assets/images/user-avatar.png"
import { AlbumsOutline, CalculatorOutline, CardOutline, CashOutline, CheckmarkOutline, DocumentTextOutline, ExitOutline, HomeOutline, MailOpenOutline, NewspaperOutline, PaperPlaneOutline, PersonOutline, ReaderOutline, SettingsOutline, WalletOutline } from 'react-ionicons'
import { useCallback, useContext, useEffect, useState } from 'react';
import { Spin } from '@arco-design/web-react';
import { getSessionStorage, removeSessionStorage, resetNavigation } from '../../common/Utils/Utils';
import PopupModal from '../PopupModal';
import { ContextAPI } from '../../common/Context/ContextApi';
import KYCVerification from '../../Screens/KYC/KYCVerification';

const Layout = ({ children, loading }) => {
    const { pathname } = useLocation()
    const [toggleStatus, settoggleStatus] = useState(true);
    const { kycModal, setkycModal } = useContext(ContextAPI)
    const [isOTPOpened, setisOTPOpened] = useState(false)
    const navigate = useNavigate();
    const item = (data) => {
        settoggleStatus(data)
    }
    useEffect(() => {
        //console.log("path", pathname);
        if (!pathname.includes('/paymenthistory')) {
            removeSessionStorage('StartDate')
            removeSessionStorage('EndDate')
        }
    }, [pathname])
    // useEffect(() => {
    //     window.addEventListener('storage', resetLocation)
    //     return () => {
    //         window.removeEventListener('storage', resetLocation)
    //     }
    // }, [])

    // const resetLocation = () => {
    //     if (!(getSessionStorage('userdetails'))) {
    //         debugger
    //         navigate('/login')
    //     }
    // }


    const { first_name, last_name } = getSessionStorage('userdetails') ? getSessionStorage('userdetails') : ""



    const paths = [
        { url: '/', title: "Home", icon: <HomeOutline width="19px" height="19px" />, icon: <HomeOutline width="19px" height="19px" /> },
        { url: '/profile', title: "Profile", icon: <PersonOutline width="19px" height="19px" /> },
        { url: '/myaccount', title: "My Account", icon: <CardOutline width="19px" height="19px" /> },
        { url: '/quickpay', title: "Quick Pay", icon: <WalletOutline width="19px" height="19px" /> },
        { url: '/mychits', title: "My Chits", icon: <NewspaperOutline width="19px" height="19px" /> },
        { url: '/myloans', title: "My Loans", icon: <CashOutline width="19px" height="19px" /> },
        { url: '/newchits', title: "New Chits", icon: <AlbumsOutline width="19px" height="19px" /> },
        { url: '/auctiondetails', title: "Auction Details", icon: <DocumentTextOutline width="19px" height="19px" /> },
        { url: '/myrequest', title: "My Request", icon: <PaperPlaneOutline width="19px" height="19px" /> },
        { url: '/emicalculator', title: "EMI Calculator", icon: <CalculatorOutline width="19px" height="19px" /> },
        { url: '/feedback-complaint', title: "Feedback/ Complaint", icon: <MailOpenOutline width="19px" height="19px" /> },
        { url: '/paymenthistory', title: "Payment History", icon: <ReaderOutline width="19px" height="19px" /> },
        // { url: '/settings', title: "Settings", icon: <SettingsOutline width="19px" height="19px" /> },
        { url: '/logout', title: "Logout", icon: <ExitOutline width="19px" height="19px" /> },
    ]

    const handleCloseModal = useCallback(() => {
        if (!isOTPOpened) {
            setkycModal(false)
        } else return

    }, [isOTPOpened])
    // const currentPage = paths.find(item => item.url === pathname).component
    return (
        <section style={{ height: '100svh' }} className="main-wrapper vw-100 overflow-hidden">
            <div className="d-flex h-100">
                <div className='sidebar_wrapper d-flex flex-column bg_master_gradient2 zi_10'>
                    <Sidebar toggled={toggleStatus} breakPoint="all">
                        <div className='d-flex'>
                            <div className='m-lg-auto px-2 py-4 ps-5 ps-lg-2'>
                                {/* <div className='text-center'>
                                    <img src={profileImg} className='h_80x w_80x bd_white border border-5 rounded-circle bg_master2' />
                                </div> */}
                                <div className='fw_500 line_control_1 max_w_200x mt-1 text-white text-center ps-3 ps-lg-0'>{first_name + ' ' + last_name}</div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <Menu
                                menuItemStyles={{
                                    button: {
                                        // the active class will be added automatically by react router
                                        // so we can use it to style the active menu item
                                        [`&.active`]: {
                                            backgroundColor: '#13395e',
                                            color: '#b6c8d9',
                                        },
                                    },
                                }}
                            >
                                {paths.map(item =>
                                    <MenuItem active={item.url === pathname} component={<Link to={item.url} />} icon={item.icon}>{item.title}</MenuItem>
                                )}

                            </Menu>
                        </div>
                        <div className="d-flex font_10 justify-content-between px-2 text-white pt-2">
                            <span className="pe-1 py-1">Pixl.ai</span>
                            <span className="pe-1 py-1">{global.version}</span>
                        </div>
                    </Sidebar>
                </div>
                {
                    toggleStatus ?
                        <div className='main-content-area active'>
                            <Spin loading={loading} dot>
                                <div className='d-flex flex-column w-100 h-100'>
                                    <Header handleToggleBtn={item} />
                                    {children}
                                </div>
                            </Spin>
                        </div>
                        :
                        <div className='main-content-area'>
                            <Spin loading={loading} dot>
                                <div className='d-flex flex-column w-100 h-100'>
                                    <Header handleToggleBtn={item} />
                                    {children}
                                </div>
                            </Spin>
                        </div>

                }

            </div>
            {kycModal &&
                // isOTPOpened:- when otp modal is opened the modal close button will hide
                <PopupModal title={''} visible={kycModal} handleClose={handleCloseModal} closeButton={!isOTPOpened}>
                    <KYCVerification isOTPOpened={isOTPOpened} setisOTPOpened={setisOTPOpened} />
                </PopupModal>
            }
        </section>
    )
}

export default Layout