import BackBtn from "../../../Components/BackBtn/BackBtn";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { FailedPopup, customLogger, sanitizedText, successPopup } from "../../../common/Utils/Utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ManageApis } from "../../../common/APIS/ManageApis";
import Layout from "../../../Components/Layout/Layout";
import { NewspaperOutline } from "react-ionicons";
import DataTable from "../../../Components/DataTable/DataTable";
import InputField from "../../../Components/InputField/InputField";
import PopupModal from "../../../Components/PopupModal";

const LoanDetails = () => {
    const { state } = useLocation()
    const { quickPaydata, loanee_id } = state || {}
    const navigation = useNavigate();
    const { token, setnotificationsDetails } = useContext(ContextAPI);
    const [LoanDetailsRemarks, setLoanDetailsRemarks] = useState('')
    const [InputData, setInputData] = useState('');
    const [LoanDetails, setLoanDetails] = useState({});
    const [paymentDetails, setpaymentDetails] = useState({})
    const [loading, setloading] = useState(false)
    const [PendingInstallment, setPendingInstallment] = useState([]);
    const [SelectedInstallment, setSelectedInstallment] = useState([]);
    const [error, setError] = useState({})
    const [ModalProps, setModalProps] = useState({
		open: false,
		title: "",
		message: '',
		props: []
	})
    

    useEffect(() => {
        
        if (loanee_id) {
            handleGetChittal()
        }
        else if (quickPaydata) {
            
            const { loan_details, payment_details, installment_details,remarks } = quickPaydata
            setLoanDetails(loan_details);
            setLoanDetailsRemarks(remarks)
            setpaymentDetails(payment_details)
            
            handlePaymentDetails(installment_details, payment_details,loan_details)
        }
        else {
            navigation(-1)
        }

        return (() => {
            setInputData('')
        })
    }, [])

    const isNonEmi = useMemo(() => paymentDetails?.collectionType?.includes('Non-EMI'), [paymentDetails?.collectionType])
    const isEmi = useMemo(() => paymentDetails?.collectionType === 'EMI', [paymentDetails?.collectionType])

    const handleGetChittal = async () => {
        setloading(true)
        const reqstBody = {
            loanee_id
        };
        let response = await ManageApis(
            "post",
            global.getLoanDetails,
            reqstBody,
            token
        );
        //console.log({ response })
        
        if (response?.response_data?.status) {
            
            if (response.response_data.data?.blocked?.status) {
                setloading(false)
                FailedPopup(response?.response_data?.data?.blocked?.message);
                navigation(-1)
            } else {
                const { payment_details, loan_details, installment_details,remarks } = response.response_data.data || {}
                setLoanDetails(loan_details);
                setLoanDetailsRemarks(remarks)
                setpaymentDetails(payment_details)
                console.log("first payment d",payment_details);
                handlePaymentDetails(installment_details, payment_details,loan_details)
                setloading(false)
               
                
            }

        } else {
            setloading(false)
            //console("res", response);
            FailedPopup(response);
        }
    };
  



    const LoanDetailsItems = [
        { key: "Scheme", value: LoanDetails.loan_description },
        { key: "Branch Name", value: LoanDetails.branch_name },
        { key: 'Name Of Loanee', value: LoanDetails.customer_name },
        { key: 'Loan Account Number', value: LoanDetails.account_no },
        { key: 'Loan Amount', value: LoanDetails.sanction_amount },
        { key: 'Rate of Interest', value:LoanDetails.interest_rate && `${LoanDetails.interest_rate} %` },
        { key: 'Outstanding Balance', value: LoanDetails.outstanding_balance },
        { key: 'Tenure', value: LoanDetails?.tenure &&`${LoanDetails?.tenure} Months` },
        { key: 'Loan Closure Amount', value: paymentDetails?.closingAmount },
        { key: 'Last Paid Upto', value: paymentDetails?.lastPaidUpto },
        { key: 'Current Installments', value: paymentDetails?.currentInstallmentNumber },
        { key: 'Current Due Date', value: paymentDetails?.dueDate },
        { key: 'EMI', value: paymentDetails?.EMI },
        // { key: 'Due Date', value: paymentDetails.dueDate },
        // {key:'Due Amount',value:LoanDetails.},
    ]
    
   
    
    let installmentDetails = []
    if(LoanDetails.loan_description == "Special Car Loan"){
        installmentDetails = [...installmentDetails, 
            { key: 'Principal Amount', value:PendingInstallment[0]?.principalAmount},
            { key: 'Penal Interest', value:PendingInstallment[0]?.penalInterest},
            { key: 'Interest Due', value: PendingInstallment[0]?.interest }
        ]
    }else{
        installmentDetails = [...installmentDetails, 
            { key: 'Interest Due', value: PendingInstallment[0]?.EMI || PendingInstallment[0]?.interest }]
    }
    installmentDetails = [...installmentDetails, 
        { key: 'From Date', value: PendingInstallment[0]?.startDate },
        { key: 'To Date', value: PendingInstallment[0]?.dueDate },
        { key: 'Suspense', value: PendingInstallment[0]?.totalSuspense },
    ]


    
    
    // const EmiInstallmentDetails = [
    //     // { key: 'Due Amount', value: PendingInstallment[0]?.totalAmount },
    //     { key: 'Principal', value: PendingInstallment[0]?.principalAmount },
    //     { key: 'Interest', value: PendingInstallment[0]?.interest },
    //     // { key: 'Penal Interest', value: PendingInstallment[0]?.penalInterest },
    //     { key: 'Suspence', value: PendingInstallment[0]?.penalInterest },
    //     // { key: '', value: '' },
    // ]
  
    const TableColumn1 = [
        {
            title: '', dataIndex: 'isChecked', render: (v, rec) =>
         
                <div className="d-flex justify-content-start">
                   <input 
                        type="checkbox" 
                        checked={rec.isChecked} 
                        className="form-check-input font_16" 
                        onChange={() => handelcheck(rec)} 
                    />
                </div>
          },
     
        { title: 'Installment', dataIndex: 'installmentNumber' },
        { title: 'Start Date', dataIndex: 'startDate' ,
            headerCellStyle: {
                minWidth: '120px',
            },
        },
        { title: 'Principal', dataIndex: 'principalAmount' ,align:'right'},
        { title: 'Interest', dataIndex: 'interest' ,align:'right'},
        { title: 'Suspense', dataIndex: 'penalInterest',align:'right' },
        { title: 'Due Amount', dataIndex: 'totalAmount',align:'right' },
   
       
    ]

    const handlePaymentDetails = (installment_details, payment_details,loan_details) => {
        let array = installment_details.filter(itm => itm.installmentNumber > 0)
        // there are two types installment 
        // 1.EMI - have lists of due installment 
        // 2.Non-EMI - have one installment with all due details
        if (payment_details?.collectionType?.includes('Non-EMI')) {
            let arr = ['interest', 'startDate', 'dueDate', 'penalInterest', 'totalSuspense', 'EMI']
            const filteredPaymentDetails = {};
            arr.forEach(key => {
                if (payment_details.hasOwnProperty(key)) {
                    filteredPaymentDetails[key] = payment_details[key];
                }
            })
            
            array = [filteredPaymentDetails]
            

            if(!array[0].dueDate){
                array[0].dueDate = installment_details[0]?.dueDate
            }


            if(loan_details.loan_description == "Special Car Loan"){
                let sumPrincipalAmount = 0
                let sumInterest = 0
                let sumPenalInterest = 0
                if(installment_details.length > 0){
                    sumPrincipalAmount = installment_details.reduce((sum, item) => {
                        return sum + item.principalAmount;
                    }, 0);
                    sumInterest = installment_details.reduce((sum, item) => {
                        return sum + item.interest;
                    }, 0);
                    sumPenalInterest = installment_details.reduce((sum, item) => {
                        return sum + item.penalInterest;
                    }, 0);
                }

                let priciplaAmountItem = {"principalAmount" : sumPrincipalAmount};
                let InterestItem = {"interest" : sumInterest};
                let penalInterestItem = {"penalInterest" : sumPenalInterest};
                Object.assign(array[0], penalInterestItem);
                Object.assign(array[0], InterestItem);
                Object.assign(array[0], priciplaAmountItem);
            }else{
                let newItem = {"principalAmount" : installment_details[0]?.principalAmount};
                Object.assign(array[0], newItem);
            }
            

        } else {
            // emi installments can select and pay thats why we use ischecked value here
            array = array.map((item) => ({ ...item, isChecked: true })
            );
        }
        setSelectedInstallment(array)
        setInputData(payment_details.totalPaymentAmount)
        setPendingInstallment(array);
    } 
    const handelcheck = useCallback(async(data, index) => {
   
        
        // block when there is only one pending installment
        // if (SelectedInstallment.length <=1) {
        //     return
        // }
        ////console("installment_no",data.installment_no);
        //Toggle checkbox
    
        let checklist = PendingInstallment.map((itm) =>
            Number(data.installmentNumber) == Number(itm.installmentNumber)
                ? { ...itm, isChecked: !itm.isChecked }
                : itm
        )
        let currentChecklist = checklist.find(itm => Number(data.installmentNumber) == Number(itm.installmentNumber))
        ////console("currentChecklist", currentChecklist);
        // check the above items and uncheck the below items
        if (currentChecklist?.isChecked == true) {
            ////console("if");
            checklist = PendingInstallment.map((itm) =>
                Number(data.installmentNumber) >= Number(itm.installmentNumber)
                    ? { ...itm, isChecked: true }
                    : itm
            )
        } else {
            ////console("else");
            checklist = PendingInstallment.map((itm) =>

                Number(data.installmentNumber) <= Number(itm.installmentNumber)
                    ? { ...itm, isChecked: false }
                    : itm

            )
        }
        setPendingInstallment(checklist);
        let SelectedPendingInstallment = checklist.filter((itm) => itm.isChecked == true)
        setSelectedInstallment(SelectedPendingInstallment);

        // fetch selected installemts ids
        let idsArray = SelectedPendingInstallment.map(item => {
            return item.installmentNumber
        })

       let payment_details =await getSelectedItemAmount(idsArray)
        if(payment_details){
            setpaymentDetails(payment_details)
            setInputData(payment_details.totalPaymentAmount)
        }
       
    }, [PendingInstallment]);

    const getSelectedItemAmount = (async(ids) => {
        let path = global.getInstallmentsByNumber
        let reqstBody
        if(loanee_id){
            reqstBody = {
                loanee_id,
                installment_id: ids
            }
        }else{
            reqstBody = {
                loanee_id: LoanDetails._id,
                installment_id: ids
            }
        }
        
        let response = await ManageApis('post', path, reqstBody, token)
        if (response?.response_data?.status) {
            
            return response.response_data?.data?.payment_details

        } else {
            FailedPopup(response);
            return false
        }
        
    });

    // const handleViewPassbook = () => {
    //     const { branch_id, chit_no } = LoanDetails
    //     navigation("ViewPassbookScreen", { loanAccountNumber: LoanDetails.account_no });
    // };

    const onInputChange = (text) => {
        text = sanitizedText(text)
        setInputData(text);
        validateInput(text);
    }
    const handleErrors = (name, message) => {
		customLogger((name, message))
		setError({ name: name, message })
	}

    const validateInput = (text) => {
        let stateObj = "";
        Number(text);
        if (isNonEmi) {
            // console.log('button stats------', text && text > paymentDetails.totalPaymentAmount && text < paymentDetails.closingAmount)
            // if (text < paymentDetails.totalPaymentAmount) {
            //     stateObj = `The minimum permissible amount is ${paymentDetails?.totalPaymentAmount}`;
            // } else if (text > paymentDetails.closingAmount) {
            //     stateObj = `The maximum permissible amount is ${paymentDetails?.closingAmount}`
            // }
            //
            if(LoanDetails.loan_description == "Gold Loan"){
                if (text < paymentDetails.totalPaymentAmount) {
                    stateObj = `The minimum permissible amount is ${paymentDetails?.totalPaymentAmount}`;
                } else if (text > (paymentDetails?.closingAmount -100)) {
                    stateObj = `The maximum permissible amount is ${paymentDetails?.closingAmount -100}`
                }
            }else{
                
                if (text < paymentDetails.totalPaymentAmount) {
                    stateObj = `The minimum permissible amount is ${paymentDetails?.totalPaymentAmount}`;
                } else if (text > paymentDetails?.closingAmount) {
                    stateObj = `The maximum permissible amount is ${paymentDetails?.closingAmount}`
                }
            }
            
            
          
        } 
        setError({message:stateObj});
        //console.log(error);
    }
    const getInputStatus = useCallback(() => {
        //debugger
        Number(InputData);
        if (isNonEmi) {
            // console.log('button stats------', InputData, paymentDetails.totalPaymentAmount, InputData < paymentDetails.closingAmount)
            if (InputData >= paymentDetails.totalPaymentAmount && InputData <= paymentDetails.closingAmount) {
                return false;
            } else {
                return true;
            }
        } else {

        }
    }, [InputData, paymentDetails]);

    const createInstallmenstArray = () => {
        let installmenstArray = SelectedInstallment.map((itm) => (
            {
                "installment_no": itm.installment_no,
                "interest": itm.interest,
                "net_amount": itm.net_amount,
                "sync_status": itm.sync_status,
                "startDate": itm.startDate,
                "dueDate": itm.dueDate
            }
        ))
        return installmenstArray
    }

    const handlePaymentList = async () => {
       
        if (!getInputStatus()) {
            if (paymentDetails?.paidPendingAmount > 0) {
                return FailedPopup(`An amount of Rs.${paymentDetails?.paidPendingAmount} is under processing against this loan. Please try after some time.`)
            }
            setloading(true)
            let reqstBody, path;
            
            if (quickPaydata) {
                path = global.addToCart
                if (paymentDetails.collectionType == "Non-EMI") {
                    reqstBody = {
                        loanee_id: LoanDetails._id,
                        type: "loan",
                        amount: InputData,
                        pay_mode: 'installment',
                        installments: PendingInstallment,
                    }
                } else {
                    reqstBody = {
                        loanee_id: LoanDetails._id,
                        type: "loan",
                        amount: InputData,
                        pay_mode: 'installment',
                        installments: createInstallmenstArray(),
                    }
                }

            } else {
                
                path = global.addToCart
                if (paymentDetails.collectionType == "Non-EMI") {
                    reqstBody = {
                        loanee_id: LoanDetails._id,
                        type: "loan",
                        amount: InputData,
                        pay_mode: 'installment',
                        installments: PendingInstallment,
                    }
                } else {
                    reqstBody = {
                        loanee_id: LoanDetails._id,
                        type: "loan",
                        amount: InputData,
                        pay_mode: 'installment',
                        installments: createInstallmenstArray(),
                    }
                }

            }
            
            //console.log("post", path, reqstBody, token)

            let response = await ManageApis("post", path, reqstBody, token);
            if (response.message == "Success") {
                setloading(false)
                setnotificationsDetails(prev => ({ ...prev, cart_count: prev.cart_count + 1 }))
                // if (!quickPaydata) {
                //     setModalProps({
                //         open: true,
                //         message: 'Item successfully added to cart, Do you want to add more items?',
                //         props: [
                //             {
                //                 text: 'Proceed to cart',
                //                 onPress: () => { navigation("/cart") },
                //                 style: 'cancel',
                //                 variant:'master',
                //             },
                //             { 
                //                 text: 'Yes', 
                //                 onPress: () => navigation("/myloans"),
                //                 variant:'master',
                //             },
                //         ]
                //     })
                // } 
                setModalProps({
                    open: true,
                    message: 'Item successfully added to cart, Do you want to add more items?',
                    props: [
                        {
                            text: 'Proceed to cart',
                            onPress: () => { navigation("/cart") },
                            style: 'cancel',
                            variant:'master',
                        },
                        { 
                            text: 'Yes', 
                            onPress: () => navigation("/myloans"),
                            variant:'master',
                        },
                    ]
                })


            } else {
                setloading(false)
                if (response.message.includes('already added')) {
                    setModalProps({
                        open: true,
                        title: "Failed!",
                        message: response.message,
                        props: [
                            
                            { 
                                style: 'cancel', 
                                text: 'Cancel', 
                                variant:'master-line', 
                                onPress: () => { setModalProps({open : false}) } 
                            },
                            {
                                text: 'Go to cart',
                                variant:'master',
                                onPress: () => { navigation("/cart") },
                            },
                        ]
                    })
                } else {
                    FailedPopup(response);
                }
            }


        } else {
            setloading(false)
        }
    };

    const CartContainer = () =>
        <div>
            <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
                {isEmi &&
                    <>
                    <div className="row mb-0 mb-sm-3">
                        <div className="col col-md-6 col-lg-8 font_13 text-md-end">Principal Amount</div>
                        <div className="col-auto col-md-6 col-lg-4 pt-1 pt-sm-0 mb-3 mb-sm-0">
                            <div className="d-flex justify-content-end">
                                <span className="text-black word_break fw_600 font_14">
                                {paymentDetails.totalFuturePrincipalAmount + paymentDetails.totalPrincipalAmount}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-0 mb-sm-3">
                        <div className="col col-md-6 col-lg-8 font_13 text-md-end">Interest</div>
                        <div className="col-auto col-md-6 col-lg-4 pt-1 pt-sm-0 mb-3 mb-sm-0">
                            <div className="d-flex justify-content-end">
                                <span className="text-black word_break fw_600 font_14">
                                {paymentDetails.totalFutureInterest + paymentDetails.totalInterest}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-0 mb-sm-3">
                        <div className="col col-md-6 col-lg-8 font_13 text-md-end">Suspense Amount</div>
                        <div className="col-auto col-md-6 col-lg-4 pt-1 pt-sm-0 mb-3 mb-sm-0">
                            <div className="d-flex justify-content-end">
                                <span className="text-black word_break fw_600 font_14">{paymentDetails.totalSuspense}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-0 mb-sm-3">
                        <div className="col col-md-6 col-lg-8 font_13 text-md-end">Installment Selected</div>
                        <div className="col-auto col-md-6 col-lg-4 pt-1 pt-sm-0 mb-3 mb-sm-0">
                            <div className="d-flex justify-content-end">
                                {!!SelectedInstallment.length &&
                                    <span className="text-black word_break fw_600 font_14">
                                        {!!SelectedInstallment.length == 0 ? `${SelectedInstallment[0]?.installmentNumber}` : `${SelectedInstallment[0]?.installmentNumber} - ${SelectedInstallment[SelectedInstallment.length - 1]?.installmentNumber}`}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mb-0 mb-sm-3">
                        <div className="col col-md-6 col-lg-8 font_13 text-md-end">Penal Interest</div>
                        <div className="col-auto col-md-6 col-lg-4 pt-1 pt-sm-0 mb-3 mb-sm-0">
                            <div className="d-flex justify-content-end">
                                <span className="text-black word_break fw_600 font_14">{paymentDetails.totalPenalInterest}</span>
                            </div>
                        </div>
                    </div>
                    </>
                }
                {paymentDetails.isPaymentEnabled &&
                    <>
                        <div className="row mb-0">
                         
                            
                               
                                    {isEmi?
                                    <>
                                        <div className="col col-md-6 col-lg-8 font_13 text-md-end pt-2">Payable Amount</div>
                                        {/* <div className="bg_gray2 border br_5 mb-3 px-3 py-2 text_gray1 text-end font_12">{InputData}</div> */}
                                        <div className="col-auto col-md-6 col-lg-4 pt-1 pt-sm-0 pb-4">
                               
                                            <div className="text-black word_break fw_600 font_14 text-end">{InputData}</div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-12 col-md-6 col-lg-8 font_13 text-md-end pt-2">Payable Amount</div>
                                        <div className="col-12 col-md-6 col-lg-4 pt-1 pt-sm-0">
                                            <InputField
                                                disabled={isEmi} // only non emi has editable this field
                                                maxLength={8}
                                                className={'text-end'}
                                                errorMessage={error?.message}
                                                seterrorMessage={handleErrors}
                                                type={'number'} 
                                                placeholder={'Enter amount'} 
                                                onchange={(text) => onInputChange(text)} 
                                                helperText={(!isEmi && InputData>0)? "Note: The amount shown is the minimum amount payable. Any amount remitted above the minimum payable will be deducted from the principal outstanding as of the current date.": ""}
                                                value={InputData}
                                            />
                                        </div> 
                                    </>
                                    }
                        </div>
                        <div className="row mb-2">
                            <div className="col-12 col-md-6 col-lg-8"></div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div>
                                    <button 
                                        className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" 
                                        onClick={(getInputStatus() || !InputData)? ()=>{return} : handlePaymentList} 
                                        disabled={getInputStatus() || !InputData}
                                    >
                                        <span className="ws_nowrp">Add to Payment List</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>


    const filteredList = useMemo(() => {
        if (!paymentDetails.showClosingAmount) {
            return LoanDetailsItems.filter(item => item.key !== 'Loan Closure Amount')
        } 
        else if (isNonEmi) {
            // these data not required in non-emi loans
            let array = ["Last Paid Upto", "Current Installments", "EMI", "Current Due Date"]
            return LoanDetailsItems.filter(item => !array.includes(item.key))
        }
        else return LoanDetailsItems
    }, [paymentDetails, LoanDetailsItems]) 


    return (
        <Layout loading={loading}>
            {ModalProps?.open &&
				<PopupModal visible={ModalProps.open} handleClose={() => setModalProps({ open: false })} title={ModalProps?.title} message={ModalProps?.message} buttons={ModalProps?.props} />
			}
            
            <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
                <div className="mb-3">
                    <div className="align-items-center d-flex justify-content-between flex-wrap">
                        <BackBtn title={'Loan Details'} />
                        <div className="col-12 col-sm-auto pt-3 pt-sm-2">
                            <div className="d-flex gap-3">
                            {!quickPaydata &&
                                <Link to={'/loanpassbook'} state={{ isEmi:isEmi, loanAccountNumber: LoanDetails.account_no }} className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp">
                                    View Loan Passbook
                                </Link>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
                    <div className="row">
                        {filteredList.map(item =>
                            <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
                                <div className="row">
                                    <div className="col-12 col-sm-6 font_13">{item.key}</div>
                                    <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                        <div className="d-flex">
                                            <span className="pe-1 d-none d-sm-block">:</span>
                                            <span className="text-black word_break fw_600 font_13">{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {LoanDetailsRemarks &&
                    <div className="pb-1 pb-3 pt-2">
                        <div className="bg_master2 font_13 px-3 py-3 rounded">
                            <span className="fw-bold">Note : </span> 
                            {LoanDetailsRemarks||""}
                        </div>
                    </div>
                }
                
                <div>
                    <div className="mb-3">
                        <div className='d-flex pt-2'>
                            <NewspaperOutline height="23px" width="23px" color={''} className="text_master" />
                            <h3 className='fw_600 font_18 text_master ms-2 mb-0'>Due List</h3>
                        </div>
                    </div>
                    <div>
                        {PendingInstallment.length ?
                            // true ?
                            isEmi ?
                                <>
                                    <div className="p-3 bg-white br_10 shadow_sm mb-3">
                                        {/* <FlatList data={PendingInstallment} renderItem={renderItem} /> */}
                                        <div className="arco_table1 table-responsive custom_scroll1">
                                            <DataTable 
                                                columnData={TableColumn1} 
                                                rowData={PendingInstallment} 
                                                total={PendingInstallment.length} 
                                                current={1}
                                                onChangeTable={(e)=> console.log(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                
                                :
                                <>
                                    <div className="p-3 bg-white br_10 shadow_sm mb-3">
                                        <div className="row">
                                            {installmentDetails?.map(item =>
                                                <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6 font_13">{item.key}</div>
                                                        <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                                            <div className="d-flex">
                                                                <span className="pe-1 d-none d-sm-block">:</span>
                                                                <span className="text-black word_break fw_600 font_13">{item.value}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                
                                    {/* <FlatList data={installmentDetails} renderItem={ExpandableCardItem} numColumns={3} /> */}
                                    {CartContainer()}
                                </>
                            :
                            <div className="p-3 bg-white br_10 shadow_sm">
                                <p style={{ textAlign: 'center', fontWeight: 600 }}>{'No pending installments in your loan at the moment.'}</p>
                            </div>

                        }
                        {isEmi &&
                            <div>
                                <div style={{ padding: 5 }}>
                                    {CartContainer()}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Layout>
      );
}

export default LoanDetails